import { useDocumentDataReduxActions } from '../../../../shared/features/documentData/hooks';
import { DocumentSettingsNavigationData } from './useDocumentSettingsNavigationData.types';
import { UseDocumentSettingsNavigationHandlersTypes } from './useDocumentSettingsNavigationHandlers.types';
import { useCallback } from 'react';
import ROUTES from '../../../constants/routes';
import { useHistory } from '../../../utils/hooks';
import { useLocation } from 'react-router-dom';

export const useDocumentSettingsNavigationHandlers = ({
  formattedData,
  localActions,
  localState
}: {
  formattedData: DocumentSettingsNavigationData['formattedData'];
  localActions: DocumentSettingsNavigationData['localActions'];
  localState: DocumentSettingsNavigationData['localState'];
}): UseDocumentSettingsNavigationHandlersTypes => {
  const history = useHistory();
  const { state } = useLocation();
  const documentDataReduxActions = useDocumentDataReduxActions();

  const handleGetDocuments = useCallback(() => {
    if (formattedData?.id && !isNaN(+formattedData?.id)) {
      documentDataReduxActions.getDocuments({ id: formattedData?.id, extend_info: true });
    }
  }, [documentDataReduxActions, formattedData?.id]);

  const handleUpdateDocuments = documentDataReduxActions.updateDocuments;

  const handleGoBack = useCallback(() => history.push(ROUTES.documents, state), [history, state]);

  const handleChangeDocumentType: UseDocumentSettingsNavigationHandlersTypes['handleChangeDocumentType'] =
    () => {
      localActions.setTypeOfDocument(typeOfDocument => !typeOfDocument);
    };

  return {
    handleGetDocuments,
    handleChangeDocumentType,
    handleUpdateDocuments,
    handleGoBack
  };
};
