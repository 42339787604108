import { useParams } from 'react-router-dom';
import type {
  DocumentVersionsDocketData,
  DocumentVersionsDocketReduxState
} from './useDocumentVersionsDocketData.types';

import { GlobalState } from 'shared/reducers';
import { getCurrentUser } from 'shared/features/user/user.selectors';
import {
  getDocumentApiTimestamp,
  getFirstDocumentApiElement
} from 'shared/features/documentData/documentData.selectors';

import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useDocumentVersionsDocketData = ({
  docDetailsInfo
}: {
  docDetailsInfo: DocumentVersionsDocketReduxState['docDetailsInfo'];
}): DocumentVersionsDocketData => {
  const [docVersion, setDocVersion] = useState(_.get(docDetailsInfo, ['versions_docket', 0], {}));

  const { id } = useParams();

  const pdfUrl = useMemo(
    () => docDetailsInfo?.document_versions?.[0]?.file_s3_pdf_url,
    [docDetailsInfo]
  );

  const versionsDocket = useMemo(() => docDetailsInfo?.versions_docket ?? [], [docDetailsInfo]);
  const versionsDocketTabTitle = useMemo(
    () => (versionsDocket?.length > 0 ? 'Versions Docket' : 'No Versions Docket'),
    [versionsDocket]
  );
  const reduxState = useSelector<GlobalState, DocumentVersionsDocketData['reduxState']>(state => ({
    currentUser: getCurrentUser(state),
    docDetailsInfo: getFirstDocumentApiElement(state),
    docTimestamp: getDocumentApiTimestamp(state)
  }));
  const localActions = useMemo(
    () => ({
      setDocVersion
    }),
    []
  );

  return {
    reduxState,
    localActions,
    formattedData: {
      id,
      pdfUrl,
      versionsDocketTabTitle,
      versionsDocket
    },
    localState: {
      docVersion
    }
  };
};
