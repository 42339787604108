import type { FC } from 'react';
import type { DocsModalProps } from './DocsModal.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './DocsModal.constants';
import * as queryKeyConstants from 'admin/constants/queryKeys';
import * as elements from 'admin/containers/DocumentManagement/elements';

import {
  useDocsModalHandlers,
  useDocsModalTableDataSelector,
  useDocsModalData,
  useDocsModalTableActions,
  useDocsModalQueries,
  useDocsModalMutations
} from './hooks';

import './_docs-modal.scss';

export const DocsModal: FC<DocsModalProps> = ({ reportId, onClose }) => {
  const { localState, localActions, reduxState, formattedData } = useDocsModalData();

  const queries = useDocsModalQueries();

  const mutations = useDocsModalMutations({
    reduxState,
    events: {
      onSubmit: () => localActions.setReloadTrigger(reloadTrigger => !reloadTrigger)
    },
    queries
  });

  const handlers = useDocsModalHandlers({
    localActions,
    localState,
    props: {
      reportId
    }
  });

  const tableActions = useDocsModalTableActions({
    localState,
    events: {
      onBulkReprocessClick: handlers.handleBulkReprocessModalToggle(true),
      onStatusAction: mutations.updateDocumentDataFlagMutation.mutateAsync
    }
  });

  const tableDataSelector = useDocsModalTableDataSelector();

  return (
    <uiLib.Modal
      title="Documents"
      isOpen
      onClose={onClose}
      shouldShowFullscreenButton
      classNames={{
        container: 'docs-modal__container'
      }}
    >
      <>
        <div className="document-management__filters">
          <elements.DocumentFiltersCompact onSubmit={handlers.handleFiltersSubmit} />
        </div>
        <uiLib.Table
          rowKey={constants.TABLE_KEYS.DOC_ID_KEY}
          actions={tableActions}
          columns={constants.COLUMNS}
          onDataFetch={handlers.handleDocumentsFetch}
          reloadTrigger={localState.reloadTrigger}
          shouldShowTableOptions
          shouldShowRefreshButton
          shouldShowFirstPageButton
          shouldShowLastPageButton
          areRowsSelectable
          localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
          initialRowsPerPage={constants.INITIAL_ROWS_PER_PAGE}
          rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
          onSelectedRowKeysChange={handlers.handleRowsSelection}
          onSelectAllClick={handlers.handleSelectAllClick}
          queryOptions={{
            queryKey: [queryKeyConstants.QUERY_KEYS.REPORT_DOCUMENTS, reportId, localState.filters],
            select: tableDataSelector
          }}
          onParamsChange={localActions.setTableParams}
          externalParams={formattedData.externalTableParams}
          queryParams={localState.tableParams}
          classNames={{
            container: 'docs-modal__table-container',
            tableContainer: 'docs-modal__table-inner-container'
          }}
        />
        {localState.isBulkReprocessModalOpen && (
          <elements.BulkReprocessModal
            selectedDocumentIds={localState.selectedDocumentIds}
            onSubmit={handlers.handleTableRefresh}
            onClose={handlers.handleBulkReprocessModalToggle(false)}
          />
        )}
      </>
    </uiLib.Modal>
  );
};
