import { useMemo } from 'react';
import _ from 'lodash';
import {
  api_getJurisdictions,
  api_getJurisdictionsCountry,
  api_getJurisdictionsCategory
} from 'shared/features/jurisdictionsData/jurisdictionsData.api';
import { TABLE_VARIABLES } from './JurisdictionTool.constants';
import { NOTIFICATION_TYPES, setNotification } from '../../components/Notification';

// ----- Load Options -----

const formatSelectOptions = (array, valueName, labelName) =>
  array.map(item => ({
    value: _.get(item, valueName, item),
    label: _.get(item, labelName, item)
  }));

export const loadJurisdictionsCategoryOptions = (name, searchValue, responseName) =>
  api_getJurisdictionsCategory({ [name]: searchValue })
    .then(jur => jur.items)
    .then(jur => formatSelectOptions(jur, responseName, responseName));

export const loadJurisdictionsCountryOptions = (name, searchValue, responseName) =>
  api_getJurisdictionsCountry({ [name]: searchValue })
    .then(jur => jur.items)
    .then(jur => formatSelectOptions(jur, responseName, responseName));

export const loadJurisdictionsOptions = (name, searchValue, responseName) =>
  api_getJurisdictions({ [name]: searchValue })
    .then(jur => jur.items)
    .then(jur => formatSelectOptions(jur, 'id', responseName));

// ----- Format Info For Table -----

const getJurisdictionInfo = jurisdiction => {
  return {
    [TABLE_VARIABLES.id]: _.get(jurisdiction, 'id', null),
    [TABLE_VARIABLES.name]: _.get(jurisdiction, 'name', ''),
    [TABLE_VARIABLES.short_name]: _.get(jurisdiction, 'short_name', ''),
    [TABLE_VARIABLES.category]: _.get(jurisdiction, 'category', ''),
    [TABLE_VARIABLES.country]: _.get(jurisdiction, 'country', ''),
    [TABLE_VARIABLES.parent_id]: _.get(jurisdiction, 'parent_id', null),
    [TABLE_VARIABLES.active]: _.get(jurisdiction, 'active', false)
  };
};

export const formatSearchValues = searchValues => {
  const formatParameters = {};
  for (const key in searchValues) {
    if (Object.prototype.hasOwnProperty.call(searchValues, key)) {
      let value = null;
      switch (key) {
        case TABLE_VARIABLES.name:
        case TABLE_VARIABLES.short_name:
          value = _.get(searchValues, [key, 'label'], null);
          break;
        case TABLE_VARIABLES.category:
        case TABLE_VARIABLES.country:
        case TABLE_VARIABLES.active:
          value = _.get(searchValues, [key, 'value'], null);
          break;
        default:
          break;
      }
      if (_.isBoolean(value) || value) formatParameters[key] = value;
    }
  }
  return formatParameters;
};

// ----- Format Info For Edit / Create -----

const getCategory = jurisdiction => {
  const category = _.get(jurisdiction, 'category', null);
  return category ? { value: category, label: category } : null;
};

const getCountry = jurisdiction => {
  const country = _.get(jurisdiction, 'country', null);
  return country ? { value: country, label: country } : null;
};

const getParent = jurisdiction => {
  const parentId = _.get(jurisdiction, 'parent_id', null);
  return parentId ? { value: parentId, label: parentId } : null;
};

export const getFormatJurisdictionInfo = jurisdiction => {
  return {
    [TABLE_VARIABLES.id]: _.get(jurisdiction, 'id', null),
    [TABLE_VARIABLES.name]: _.get(jurisdiction, 'name', ''),
    [TABLE_VARIABLES.short_name]: _.get(jurisdiction, 'short_name', ''),
    [TABLE_VARIABLES.active]: _.get(jurisdiction, 'active', false),
    [TABLE_VARIABLES.category]: getCategory(jurisdiction),
    [TABLE_VARIABLES.country]: getCountry(jurisdiction),
    [TABLE_VARIABLES.parent_id]: getParent(jurisdiction)
  };
};

export const fromJurisdictionToRawInfo = (newInfo, oldInfo) => {
  const jurisdiction = {};
  const errors = [];

  const setDifference = ({
    /*
      setDifference - function to check difference between old and edit data
        if there is some difference files send to BE for save

      newPath (required) - array
        - get new data
        - path in crawlDetails (check CRAWL_DETAILS const)
      oldPath (required) - array
        - get old data for check
        - path in original data from BE
        - used like path for set (show the right place)
      superPath (optional) - array
        - path to set data for request in special place
      predefinedValue (optional) - value (string, number, bool, array, object)
        - takes like new data (helps if data have special format or addition)
      checkFunction (optional) - function (return string)
        - function to check that new variable is correct
        - should get the value and return string error or false
    */
    newPath,
    oldPath,
    superPath,
    predefinedValue,
    checkFunction
  }) => {
    const valueNew = predefinedValue || _.get(newInfo, newPath, null);
    const valueOld = _.get(oldInfo, oldPath, null);
    if (!_.isEqual(valueNew, valueOld)) {
      _.set(jurisdiction, superPath || oldPath, valueNew);
      if (checkFunction) {
        const result = checkFunction(valueNew);
        if (result) errors.push({ text: result, title: newPath[0] });
      }
    }
  };

  setDifference({
    newPath: [TABLE_VARIABLES.id],
    oldPath: ['id']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.parent_id, 'value'],
    oldPath: ['parent_id']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.name],
    oldPath: ['name']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.short_name],
    oldPath: ['short_name']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.category, 'value'],
    oldPath: ['category']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.country, 'value'],
    oldPath: ['country']
  });
  setDifference({
    newPath: [TABLE_VARIABLES.active],
    oldPath: ['active']
  });

  if (errors.length) {
    errors.forEach(error =>
      setNotification({ message: error, type: NOTIFICATION_TYPES.ERROR, width: 800 })
    );
    return null;
  }

  return jurisdiction;
};

// export only for tests
export { formatSelectOptions, getJurisdictionInfo, getCategory, getCountry, getParent };
