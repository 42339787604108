import type { ComponentProps } from 'react';
import type { useDocumentFiltersAsyncLoaders } from './useDocumentFiltersAsyncLoaders';

import * as uiLib from '@compliance.ai/web-components';
import * as documentConstants from 'admin/constants/document';
import * as constants from '../DocumentFilters.constants';

export const useDocumentFiltersCompactConfig = ({
  filterAsyncLoaders
}: {
  filterAsyncLoaders: ReturnType<typeof useDocumentFiltersAsyncLoaders>;
}): ComponentProps<typeof uiLib.Filters>['config'] => [
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.PIPELINE_STATUSES,
    label: 'Status',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[documentConstants.DOCUMENT_ATTRIBUTES.PIPELINE_STATUS]
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.INTERACTION_STATUSES,
    label: 'State',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[documentConstants.DOCUMENT_ATTRIBUTES.INTERACTION_STATUS]
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.JURISDICTION,
    label: 'Jurisdiction',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[documentConstants.DOCUMENT_ATTRIBUTES.JURISDICTION]
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.AGENCY_ID,
    label: 'Agency',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[documentConstants.DOCUMENT_ATTRIBUTES.AGENCY_ID]
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.DOC_SCRAPPED_CATEGORY,
    label: 'Scraped Category',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[documentConstants.DOCUMENT_ATTRIBUTES.DOC_SCRAPPED_CATEGORY]
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.CAI_CATEGORY_IDS,
    label: 'Document Type / Category',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_GROUP_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[documentConstants.DOCUMENT_ATTRIBUTES.CAI_CATEGORY_IDS]
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.OFFICIAL_ID,
    label: 'Official ID',
    isFixed: true,
    type: uiLib.FILTER_TYPES.TEXT_WITH_MODIFIERS,
    inputComponentProps: {
      shouldUseExtraModifiers: true
    }
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.DOCKET_ID,
    label: 'Docket ID',
    isFixed: true,
    type: uiLib.FILTER_TYPES.TEXT_WITH_MODIFIERS,
    inputComponentProps: {
      shouldUseExtraModifiers: true
    }
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.CITATION,
    label: 'Citations',
    isFixed: true,
    type: uiLib.FILTER_TYPES.TEXT_WITH_MODIFIERS,
    inputComponentProps: {
      shouldUseExtraModifiers: true
    }
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.PUBLICATION_DATE,
    label: 'Publication Date',
    isFixed: true,
    type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
    inputComponentProps: {
      whitelistedModifiers: constants.DATE_FILTER_ALLOWED_MODIFIERS
    }
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.HAS_UNOFFICIAL_PUBLICATION_DATE,
    label: 'Unofficial Publication Date',
    isFixed: true,
    type: uiLib.FILTER_TYPES.SELECT,
    inputComponentProps: {
      options: constants.BOOLEAN_OPTIONS
    }
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.DIFFABLE,
    label: 'Diffable',
    isFixed: true,
    type: uiLib.FILTER_TYPES.SELECT,
    inputComponentProps: {
      options: constants.BOOLEAN_OPTIONS
    }
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.CREATED_AT,
    label: 'Created At',
    isFixed: true,
    type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
    inputComponentProps: {
      whitelistedModifiers: constants.DATE_FILTER_ALLOWED_MODIFIERS
    }
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.UPDATED_AT,
    label: 'Updated At',
    isFixed: true,
    type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
    inputComponentProps: {
      whitelistedModifiers: constants.DATE_FILTER_ALLOWED_MODIFIERS
    }
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.TITLE,
    label: 'Title',
    isFixed: true,
    type: uiLib.FILTER_TYPES.TEXT_WITH_MODIFIERS,
    inputComponentProps: {
      shouldUseExtraModifiers: true
    }
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.EITL_LABELS,
    label: 'Eitl Label',
    isFixed: true,
    type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
    shouldPrefetchOptions: true,
    onOptionsLoad: filterAsyncLoaders[documentConstants.DOCUMENT_ATTRIBUTES.EITL_LABELS]
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.PDF_URL,
    label: 'PDF URL',
    isFixed: true,
    placeholder: 'Enter a value',
    type: uiLib.FILTER_TYPES.TEXT_WITH_MODIFIERS,
    inputComponentProps: {
      shouldUseExtraModifiers: true
    }
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.WEB_URL,
    label: 'Web URL',
    isFixed: true,
    placeholder: 'Enter a value',
    type: uiLib.FILTER_TYPES.TEXT_WITH_MODIFIERS,
    inputComponentProps: {
      shouldUseExtraModifiers: true
    }
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.IS_SHELF_EXIST,
    label: 'Document Shelf Exists',
    isFixed: true,
    type: uiLib.FILTER_TYPES.SELECT,
    inputComponentProps: {
      options: constants.BOOLEAN_OPTIONS
    }
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.SHELF_ID,
    label: 'Document Shelf Id',
    isFixed: true,
    type: uiLib.FILTER_TYPES.NUMBER
  },
  {
    key: documentConstants.DOCUMENT_ATTRIBUTES.ROOT_SHELF_ID,
    label: 'Root Document Shelf Id',
    isFixed: true,
    type: uiLib.FILTER_TYPES.NUMBER
  }
];
