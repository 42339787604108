import React from 'react';
// icons - start
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import ReplayRoundedIcon from '@material-ui/icons/ReplayRounded';
// icons - end
import RightTabs from './RightTabs';
import DocumentDetails from './DocumentDetails/DocumentDetails';
import DocumentVersionHistory from './DocumentVersionHistory/DocumentVersionHistory';
import DocumentRelations from './DocumentRelations/DocumentRelations';
import Button from '../../components/Button';
import * as uiLib from '@compliance.ai/web-components';
import { TooltipMessage } from '../../components/Tooltip';
import DocumentVersionsDocket from './DocumentVersionsDocket/DocumentVersionsDocket';
import { useDocumentSettingsNavigationData } from './hooks/useDocumentSettingsNavigationData';
import { useDocumentSettingsNavigationHandlers } from './hooks/useDocumentSettingsNavigationHandlers';
import { useDocumentSettingsNavigationLifecycle } from './hooks/useDocumentSettingsNavigationLifecycle';
import * as uilib from '@material-ui/icons';

const DocumentSettingsNavigation = ({}) => {
  const { localState, localActions, formattedData, reduxState } =
    useDocumentSettingsNavigationData();
  const handlers = useDocumentSettingsNavigationHandlers({
    formattedData,
    localActions,
    localState
  });
  useDocumentSettingsNavigationLifecycle({
    onGetDocument: handlers.handleGetDocuments
  });

  return (
    <>
      <TooltipMessage classNameTooltipID="global-tooltip" />
      <RightTabs
        extraInfo={
          <div className="document-close-btn">
            <Button onClick={handlers?.handleGoBack}>
              <CloseIcon fontSize="large" />
            </Button>
            <uiLib.Typography className="document-close-btn__text">Pdf/Txt</uiLib.Typography>
            <uiLib.Switch
              isChecked={localState.typeOfDocument}
              onChange={handlers.handleChangeDocumentType}
              classNames={{
                container: 'document-close-btn__switch'
              }}
            />
          </div>
        }
      >
        <DocumentDetails
          label="Details"
          icon={<EditOutlinedIcon />}
          id={formattedData?.id}
          docDetailsInfo={reduxState?.docDetailsInfo}
          docTimestamp={reduxState?.docTimestamp}
          user={reduxState?.currentUser}
          updateDoc={handlers?.handleUpdateDocuments}
          shouldShowText={localState.typeOfDocument}
        />
        <DocumentVersionHistory
          label="Version History"
          icon={<ReplayRoundedIcon />}
          docDetailsInfo={reduxState?.docDetailsInfo}
          docTimestamp={reduxState?.docTimestamp}
          shouldShowText={localState.typeOfDocument}
        />
        <DocumentVersionsDocket
          label="Versions Docket"
          icon={<uilib.NotificationsActiveRounded />}
          docDetailsInfo={reduxState?.docDetailsInfo}
          docTimestamp={reduxState?.docTimestamp}
        />
        <DocumentRelations
          label="Document Relations"
          icon={<uilib.EditLocationOutlined />}
          docDetailsInfo={reduxState?.docDetailsInfo}
          docTimestamp={reduxState?.docTimestamp}
          user={reduxState?.currentUser}
          shouldShowText={localState.typeOfDocument}
        />
      </RightTabs>
    </>
  );
};

export default DocumentSettingsNavigation;
