import type { DocumentSettingsNavigationData } from '../../hooks/useDocumentSettingsNavigationData.types';
import type { DocumentDetailsData } from './useDocumentDetailsData.types';
import type {
  DocumentDetailsHandlers,
  HandlerActionsParams
} from './useDocumentDetailsHandlers.types';

import { handleRenderDocument } from '../../DocumentPage.helpers';
import { DOCUMENT_GENERAL } from '../DocumentDetails.constants';

import { useCallback } from 'react';

export const useDocumentDetailsHandlers = ({
  localActions,
  formattedData,
  props
}: {
  localActions: DocumentDetailsData['localActions'];
  formattedData: DocumentDetailsData['formattedData'];
  props: {
    shouldShowText: DocumentSettingsNavigationData['localState']['typeOfDocument'];
  };
}): DocumentDetailsHandlers => {
  const handleTakeAction = (params: HandlerActionsParams) => {
    localActions?.setFlagAction(params[DOCUMENT_GENERAL.pipelineStatus]);
  };

  const handleHideModal = () => {
    localActions?.setFlagAction('');
  };

  const handleRenderPdf = useCallback(() => {
    handleRenderDocument({
      pdfUrl: formattedData.pdfUrl as string,
      txtUrl: formattedData.txtUrl as string,
      isTxt: props.shouldShowText
    });
  }, [formattedData.pdfUrl, formattedData.txtUrl, props.shouldShowText]);

  return {
    handleTakeAction,
    handleHideModal,
    handleRenderPdf
  };
};
