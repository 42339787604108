import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as components from 'admin/components';

export const TABLE_LOCAL_STORAGE_KEY = 'crawlActivityDocsModalDocsTable';
export const INITIAL_ROWS_PER_PAGE = 50;
export const ROWS_PER_PAGE_OPTIONS = [50, 100, 200, 500, 1000, 2500, 5000];

export enum TABLE_KEYS {
  ID = 'id',
  STATUS = 'status',
  REASON = 'reason',
  TITLE = 'title',
  PUBLICATION_DATE = 'publication_date',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  PDF_URL = 'pdf_url',
  WEB_URL = 'web_url',
  CATEGORY = 'category',
  PIPELINE_STATUS = 'pipeline_status',
  AUDIT_ENTRIES = 'auditEntries',
  EVENTS = 'events',
  DOC_ID_KEY = 'doc_id_key',
  OFFICIAL_ID = 'official_id',
  MASTER_DOC_ID = 'master_id'
}

export const COLUMNS: Required<ComponentProps<typeof uiLib.Table>>['columns'] = [
  {
    title: 'Title',
    dataKey: TABLE_KEYS.TITLE,
    isInitiallyVisible: true,
    isDynamic: false,
    width: uiLib.TABLE_COLUMN_WIDTH.L,
    Component: uiLib.TextCell
  },
  {
    title: 'Status',
    dataKey: TABLE_KEYS.STATUS,
    isInitiallyVisible: true,
    isDynamic: false,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    Component: elements.ReportDocumentCrawlStatusCell
  },
  {
    title: 'Reason',
    dataKey: TABLE_KEYS.REASON,
    isInitiallyVisible: true,
    isDynamic: false,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    Component: uiLib.TextCell
  },
  {
    title: 'Doc Id',
    dataKey: TABLE_KEYS.ID,
    isInitiallyVisible: true,
    isDynamic: false,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    Component: uiLib.LinkCell
  },
  {
    title: 'Audit entries log',
    dataKey: TABLE_KEYS.AUDIT_ENTRIES,
    isDynamic: true,
    isInitiallyVisible: true,
    render: components.AuditEntriesCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Event log',
    dataKey: TABLE_KEYS.EVENTS,
    isDynamic: true,
    isInitiallyVisible: true,
    render: components.EventsCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Pipeline Status',
    dataKey: TABLE_KEYS.PIPELINE_STATUS,
    isInitiallyVisible: true,
    isDynamic: false,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    render: components.DocumentStatusCell
  },
  {
    title: 'Pub Date',
    dataKey: TABLE_KEYS.PUBLICATION_DATE,
    isInitiallyVisible: true,
    isDynamic: false,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    Component: uiLib.DateCell
  },
  {
    title: 'Created Date',
    dataKey: TABLE_KEYS.CREATED_AT,
    isInitiallyVisible: true,
    isDynamic: false,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    Component: uiLib.DateCell
  },
  {
    title: 'Updated Date',
    dataKey: TABLE_KEYS.UPDATED_AT,
    isInitiallyVisible: true,
    isDynamic: false,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    Component: uiLib.DateCell
  },
  {
    title: 'PDF URL',
    dataKey: TABLE_KEYS.PDF_URL,
    isInitiallyVisible: true,
    isDynamic: false,
    width: uiLib.TABLE_COLUMN_WIDTH.M,
    Component: uiLib.LinkCell
  },
  {
    title: 'Web URL',
    dataKey: TABLE_KEYS.WEB_URL,
    isInitiallyVisible: true,
    isDynamic: false,
    width: uiLib.TABLE_COLUMN_WIDTH.M,
    Component: uiLib.LinkCell
  },
  {
    title: 'Scraped Category',
    dataKey: TABLE_KEYS.CATEGORY,
    isInitiallyVisible: true,
    isDynamic: false,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    Component: uiLib.TextCell
  },
  {
    title: 'Official id',
    dataKey: TABLE_KEYS.OFFICIAL_ID,
    isInitiallyVisible: true,
    isDynamic: false,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    Component: uiLib.TextCell
  },
  {
    title: 'Master Doc Id',
    dataKey: TABLE_KEYS.MASTER_DOC_ID,
    isInitiallyVisible: true,
    isDynamic: false,
    width: uiLib.TABLE_COLUMN_WIDTH.S,
    Component: uiLib.LinkCell
  }
];
