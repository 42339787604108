import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import DocumentRelationsInfo from './DocumentRelationsInfo';
import { handleRenderDocument } from '../DocumentPage.helpers';

const DocumentRelations = ({ docDetailsInfo, docTimestamp, user, shouldShowText }) => {
  const [docRelation, setDocRelation] = useState(
    lodash.get(docDetailsInfo, ['document_relations', 0], {})
  );

  useEffect(() => {
    const pdfFile = lodash.get(docDetailsInfo, ['document_versions', 0, 'file_s3_pdf_url'], '');
    const txtFile = lodash.get(docDetailsInfo, ['document_versions', 0, 'file_s3_txt_url'], '');
    handleRenderDocument({
      pdfUrl: pdfFile,
      txtUrl: txtFile,
      isTxt: shouldShowText
    });
    // TODO Fix deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docTimestamp, shouldShowText, docDetailsInfo]);

  const changeCurrentDocRelation = id =>
    setDocRelation(
      lodash.get(docDetailsInfo, 'document_relations', []).find(item => item.id === id)
    );

  return (
    <div className="document-list">
      <div className="document-text" id="document-text" />
      <div className="document-list_details">
        <DocumentRelationsInfo
          docDetailsInfo={docDetailsInfo}
          currentDocRelation={docRelation}
          changeCurrentDocRelation={changeCurrentDocRelation}
          user={user}
        />
      </div>
    </div>
  );
};

DocumentRelations.propTypes = {
  docDetailsInfo: PropTypes.shape({}).isRequired,
  docTimestamp: PropTypes.string,
  user: PropTypes.shape({}).isRequired
};

DocumentRelations.defaultProps = {
  docTimestamp: ''
};

export default DocumentRelations;
