import { DocumentSettingsNavigationData } from '../../hooks/useDocumentSettingsNavigationData.types';
import { useMemo, useState } from 'react';
import { DocumentDetailsData } from './useDocumentDetailsData.types';

export const useDocumentDetailsData = ({
  docDetailsInfo
}: {
  docDetailsInfo: DocumentSettingsNavigationData['reduxState']['docDetailsInfo'];
}): DocumentDetailsData => {
  const [flagAction, setFlagAction] = useState<DocumentDetailsData['localState']['flagAction']>('');

  const formattedData: DocumentDetailsData['formattedData'] = useMemo(() => {
    const documentVersion = docDetailsInfo?.document_versions?.[0];
    return {
      pdfUrl: documentVersion?.file_s3_pdf_url,
      txtUrl: documentVersion?.file_s3_txt_url
    };
  }, [docDetailsInfo]);

  const localActions = useMemo(
    () => ({
      setFlagAction
    }),
    []
  );

  return {
    formattedData,
    localActions,
    localState: {
      flagAction
    }
  };
};
