import type { DocumentVersionsFromResponse } from 'shared/features/documentData/documentData.types';
import type { DocumentVersionsDocketData } from './useDocumentVersionsDocketData.types';
import type { DocumentVersionsDocketHandlers } from './useDocumentVersionsDocketHandlers.types';
// library pdfobject don't have type declaration why we use next two lines
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PDFobjecct from 'pdfobject';
import { useCallback } from 'react';

export const useDocumentVersionsDocketHandlers = ({
  localActions,
  localState,
  formattedData,
  reduxState
}: {
  localActions: DocumentVersionsDocketData['localActions'];
  localState: DocumentVersionsDocketData['localState'];
  formattedData: DocumentVersionsDocketData['formattedData'];
  reduxState: DocumentVersionsDocketData['reduxState'];
}): DocumentVersionsDocketHandlers => {
  const handleRenderPdf = useCallback(() => {
    const pdfUrl = localState?.docVersion?.file_s3_pdf_url;
    if (pdfUrl) {
      PDFobjecct.embed(String(pdfUrl), 'div#document-text');
    }
  }, [localState?.docVersion?.file_s3_pdf_url]);

  const changeCurrentDocVersion = (id: number) => {
    localActions.setDocVersion(
      formattedData?.versionsDocket?.find((item: DocumentVersionsFromResponse) => item.id === id)
    );
    handleRenderPdf();
  };

  return {
    handleRenderPdf,
    changeCurrentDocVersion
  };
};
