import type { DocsModalData } from './useDocsModalData.types';
import type { GlobalState } from 'shared/reducers';
import * as uiLib from '@compliance.ai/web-components';
import * as userSelectors from 'shared/features/user/user.selectors';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useDocsModalData = (): DocsModalData => {
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<
    DocsModalData['localState']['selectedDocumentIds']
  >([]);
  const [reloadTrigger, setReloadTrigger] =
    useState<DocsModalData['localState']['reloadTrigger']>(false);

  const [isBulkReprocessModalOpen, setIsBulkReprocessModalOpen] =
    useState<DocsModalData['localState']['isBulkReprocessModalOpen']>(false);

  const [filters, setFilters] = useState<DocsModalData['localState']['filters']>({});
  const [tableParams, setTableParams] = useState<DocsModalData['localState']['tableParams']>({
    ...uiLib.DEFAULT_TABLE_PARAMS,
    [uiLib.TABLE_PARAMS.LIMIT]: 50
  });
  const [deselectAllDocumentsTrigger, setDeselectAllDocumentsTrigger] =
    useState<DocsModalData['localState']['deselectAllDocumentsTrigger']>(false);

  const localState: DocsModalData['localState'] = {
    isBulkReprocessModalOpen,
    selectedDocumentIds,
    reloadTrigger,
    filters,
    tableParams,
    deselectAllDocumentsTrigger
  };

  const localActions: DocsModalData['localActions'] = useMemo(
    () => ({
      setIsBulkReprocessModalOpen,
      setSelectedDocumentIds,
      setReloadTrigger,
      setFilters,
      setTableParams,
      setDeselectAllDocumentsTrigger
    }),
    []
  );

  const formattedData: DocsModalData['formattedData'] = useMemo(
    () => ({
      externalTableParams: {
        ...localState.tableParams,
        [uiLib.EXTERNAL_TABLE_PARAMS.TRIGGER_DESELECT_ALL]: localState.deselectAllDocumentsTrigger
      }
    }),
    [localState.deselectAllDocumentsTrigger, localState.tableParams]
  );

  const reduxState = useSelector<GlobalState, DocsModalData['reduxState']>(state => ({
    user: userSelectors.getCurrentUser(state)
  }));

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
