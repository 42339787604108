import type { DocumentSettingsNavigationData } from './useDocumentSettingsNavigationData.types';
import type { GlobalState } from 'shared/reducers';

import { getCurrentUser } from 'shared/features/user/user.selectors';
import {
  getDocumentApiTimestamp,
  getFirstDocumentApiElement
} from 'shared/features/documentData/documentData.selectors';

import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';

export const useDocumentSettingsNavigationData = (): DocumentSettingsNavigationData => {
  const { id } = useParams();

  const [typeOfDocument, setTypeOfDocument] =
    useState<DocumentSettingsNavigationData['localState']['typeOfDocument']>(false);

  const reduxState = useSelector<GlobalState, DocumentSettingsNavigationData['reduxState']>(
    state => ({
      currentUser: getCurrentUser(state),
      docDetailsInfo: getFirstDocumentApiElement(state),
      docTimestamp: getDocumentApiTimestamp(state)
    })
  );

  const localState: DocumentSettingsNavigationData['localState'] = {
    typeOfDocument
  };

  const localActions: DocumentSettingsNavigationData['localActions'] = useMemo(
    () => ({
      setTypeOfDocument
    }),
    []
  );

  return {
    localState,
    localActions,
    reduxState,
    formattedData: {
      id
    }
  };
};
