import type { FC } from 'react';
import type { DocumentFiltersProps } from './DocumentFilters.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useDocumentFiltersAsyncLoaders,
  useDocumentFiltersCompactConfig,
  useDocumentFiltersHandlers
} from './hooks';

import './_document-filters.scss';

export const DocumentFiltersCompact: FC<DocumentFiltersProps> = ({ onSubmit }) => {
  const filterAsyncLoaders = useDocumentFiltersAsyncLoaders();

  const filterConfig = useDocumentFiltersCompactConfig({
    filterAsyncLoaders
  });

  const handlers = useDocumentFiltersHandlers({
    props: {
      onSubmit
    }
  });

  return (
    <uiLib.FiltersCompact
      config={filterConfig}
      values={{}}
      searchConfig={{ withSearch: true }}
      onSubmit={handlers.handleFiltersSubmit}
      dateFormat={uiLib.getLocalDateFormat()}
      classNames={{ container: 'document-filters__compact_container' }}
    />
  );
};
