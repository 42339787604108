import PDFobjecct from 'pdfobject';
import * as constants from './DocumentPage.constants';

export const handleRenderPdfUrl = (url: string) => {
  PDFobjecct.embed(url, constants.TEXT_RENDER_ELEMENT_ID);
};

export const handleRenderTextUrl = async (url: string) => {
  try {
    const docResponse = await fetch(url);
    const docBlob = await docResponse.blob();
    const reader = new FileReader();
    reader.addEventListener('loadend', function () {
      const documentDiv = document.querySelector(constants.TEXT_RENDER_ELEMENT_ID);
      const text = document.createTextNode(String(reader.result));
      if (documentDiv?.innerHTML) {
        documentDiv.innerHTML = '';
      }
      documentDiv?.appendChild(text);
    });
    reader.readAsText(docBlob);
  } catch (error) {
    console.log(`handleRenderTextUrl failed with error: ${error}`);
  }
};

export const handleRenderDocument = ({
  pdfUrl,
  txtUrl,
  isTxt
}: {
  pdfUrl: string;
  txtUrl: string;
  isTxt: boolean;
}) => {
  if (isTxt) {
    handleRenderTextUrl(txtUrl);
  } else {
    handleRenderPdfUrl(pdfUrl);
  }
};
